import 'antd/dist/antd.css'
import {
  useBalance,
  useContractLoader,
  useContractReader,
  useUserProviderAndSigner,
} from 'eth-hooks'
import { useExchangeEthPrice } from 'eth-hooks/dapps/dex'
import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch, useLocation, Link, useHistory } from 'react-router-dom'
import './App.css'
import { Contract, ThemeSwitch } from './components'
import { NETWORKS, ALCHEMY_KEY } from './constants'
import externalContracts from './contracts/external_contracts'
import deployedContracts from './contracts/hardhat_contracts.json'
import { getRPCPollTime, Transactor, Web3ModalSetup } from './helpers'
import { Home } from './views'
import { useStaticJsonRPC, useGasPrice } from './hooks'
import { Account, NetworkDisplay } from './components'
import InfinityYieldCAKE from './views/CAKEBoostVault'
import InfinityYieldUSDT from './views/USDTSALPVault'
import Logo from './images/logo.png'

const { ethers } = require('ethers')
const initialNetwork = NETWORKS.bsc // <------- select your target frontend network (localhost, goerli, xray, mainnet)
const DEBUG = true
const NETWORKCHECK = true
const USE_BURNER_WALLET = true // toggle burner wallet feature
const USE_NETWORK_SELECTOR = false

const web3Modal = Web3ModalSetup()

const providers = [
  'https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406',
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  'https://rpc.scaffoldeth.io:48544',
]

function App(props) {
  const networkOptions = [initialNetwork.name, 'mainnet', 'goerli']
  const [injectedProvider, setInjectedProvider] = useState()
  const [address, setAddress] = useState()
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0])
  const location = useLocation()
  const targetNetwork = NETWORKS[selectedNetwork]
  const blockExplorer = targetNetwork.blockExplorer
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER
      ? process.env.REACT_APP_PROVIDER
      : targetNetwork.rpcUrl,
  ])
  const [isOpen, setIsOpen] = useState(false)
  const handleLinkClick = () => {
    setIsOpen(false)
  }

  console.log('localProvider', localProvider)

  const mainnetProvider = useStaticJsonRPC(providers, localProvider)
  const localProviderPollingTime = getRPCPollTime(localProvider)
  const mainnetProviderPollingTime = getRPCPollTime(mainnetProvider)

  if (DEBUG) console.log(`Using ${selectedNetwork} network`)
  if (DEBUG) console.log('📡 Connecting to Mainnet Ethereum')

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider()
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == 'function'
    ) {
      await injectedProvider.provider.disconnect()
    }
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }

  const price = useExchangeEthPrice(
    targetNetwork,
    mainnetProvider,
    mainnetProviderPollingTime,
  )

  const gasPrice = useGasPrice(
    targetNetwork,
    'FastGasPrice',
    localProviderPollingTime,
  )
  const userProviderAndSigner = useUserProviderAndSigner(
    injectedProvider,
    localProvider,
    USE_BURNER_WALLET,
  )
  const userSigner = userProviderAndSigner.signer

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress()
        setAddress(newAddress)
      }
    }
    getAddress()
  }, [userSigner])

  const localChainId =
    localProvider && localProvider._network && localProvider._network.chainId
  const selectedChainId =
    userSigner &&
    userSigner.provider &&
    userSigner.provider._network &&
    userSigner.provider._network.chainId

  const tx = Transactor(userSigner, gasPrice)

  const yourLocalBalance = useBalance(
    localProvider,
    address,
    localProviderPollingTime,
  )

  const yourMainnetBalance = useBalance(
    mainnetProvider,
    address,
    mainnetProviderPollingTime,
  )
  const contractConfig = {
    deployedContracts: deployedContracts || {},
    externalContracts: externalContracts || {},
  }

  const readContracts = useContractLoader(localProvider, contractConfig)
  const writeContracts = useContractLoader(
    userSigner,
    contractConfig,
    localChainId,
  )
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig)
  const myMainnetDAIBalance = useContractReader(
    mainnetContracts,
    'DAI',
    'balanceOf',
    ['0x34aA3F359A9D614239015126635CE7732c18fDF3'],
    mainnetProviderPollingTime,
  )
  const purpose = useContractReader(
    readContracts,
    'YourContract',
    'purpose',
    [],
    localProviderPollingTime,
  )
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log(
        '_____________________________________ 🏗 scaffold-eth _____________________________________',
      )
      console.log('🌎 mainnetProvider', mainnetProvider)
      console.log('🏠 localChainId', localChainId)
      console.log('👩‍💼 selected address:', address)
      console.log('🕵🏻‍♂️ selectedChainId:', selectedChainId)
      console.log(
        '💵 yourLocalBalance',
        yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : '...',
      )
      console.log(
        '💵 yourMainnetBalance',
        yourMainnetBalance
          ? ethers.utils.formatEther(yourMainnetBalance)
          : '...',
      )
      console.log('📝 readContracts', readContracts)
      console.log('🌍 DAI contract on mainnet:', mainnetContracts)
      console.log('💵 yourMainnetDAIBalance', myMainnetDAIBalance)
      console.log('🔐 writeContracts', writeContracts)
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
    myMainnetDAIBalance,
  ])

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.requestProvider()
    setInjectedProvider(new ethers.providers.Web3Provider(provider))
    provider.on('chainChanged', (chainId) => {
      console.log(`chain changed to ${chainId}! updating providers`)
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })
    provider.on('accountsChanged', () => {
      console.log(`account changed!`)
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })
    provider.on('disconnect', (code, reason) => {
      console.log(code, reason)
      logoutOfWeb3Modal()
    })
    // eslint-disable-next-line
  }, [setInjectedProvider])

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal()
    }
    const checkSafeApp = async () => {
      if (await web3Modal.isSafeApp()) {
        loadWeb3Modal()
      }
    }
    checkSafeApp()
  }, [loadWeb3Modal])
  return (
    <div>
      <div className="App">
        <NetworkDisplay
          NETWORKCHECK={NETWORKCHECK}
          localChainId={localChainId}
          selectedChainId={selectedChainId}
          targetNetwork={targetNetwork}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
        />

        <Switch>
          <Route exact path="/">
            <Home
              address={address}
              tx={tx}
              writeContracts={writeContracts}
              readContracts={readContracts}
            />
          </Route>
          <Route exact path="/cakevault">
            <InfinityYieldCAKE
              address={address}
              tx={tx}
              writeContracts={writeContracts}
              readContracts={readContracts}
            />
          </Route>
          <Route exact path="/usdtvault">
            <InfinityYieldUSDT
              address={address}
              tx={tx}
              writeContracts={writeContracts}
              readContracts={readContracts}
            />
          </Route>
        </Switch>

        <div className="ui-kit">
          <div className="navbar wf-section">
            <div
              data-collapse="medium"
              data-animation="default"
              data-duration={400}
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="cf-navigation-3 desktop w-nav"
            >
              <div className="cf-navigation-3-container">
                <div className="cf-navigation-left-2">
                  <div className="">
                    <Link
                      to="/"
                      target="_blank"
                      rel="noreferrer"
                      className="cf-navbar-3-brand w-nav-brand"
                    >
                      <img
                        src={Logo}
                        loading="lazy"
                        alt="Logo of our cutting-edge Web3 DeFi DApp"
                        width={300}
                        style={{ position: 'relative', left: '-10px' }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="cf-navigation-3-center">
                  <nav
                    role="navigation"
                    className="cf-navigation-3-menu w-nav-menu mr-100"
                  >
                    <a
                      href="https://app.gitbook.com/o/dZD0AzCezpv04z1sZHXD/s/ESuTLt4gXihCEvHpOWlZ/"
                      className="cf-nav-3-link w-nav-link"
                    >
                      Doc's
                    </a>
                  </nav>
                </div>
              </div>
            </div>

            <div
              data-collapse="medium"
              data-animation="default"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              data-doc-height={1}
              role="banner"
              className="cf-navigation-3 mobile w-nav"
            >
              <div className="cf-navigation-3-container">
                <div className="cf-navigation-left-2">
                  <a
                    href="#!"
                    target="_blank"
                    rel="noreferrer"
                    className="cf-navbar-3-brand w-nav-brand"
                  >
                    <img
                      src={Logo}
                      loading="lazy"
                      alt="Image showcasing the scalability of our DeFi DApp on multiple blockchain networks"
                      width={300}
                      style={{ position: 'relative', left: '-10px' }}
                    />
                  </a>
                </div>

                <div className="navigation">
                  <input
                    type="checkbox"
                    className="navigation__checkbox"
                    id="navi-toggle"
                    checked={isOpen}
                    onChange={() => setIsOpen(!isOpen)}
                  />

                  <label for="navi-toggle" className="navigation__button">
                    <span className="navigation__icon">&nbsp;</span>
                  </label>

                  <div className="navigation__background">&nbsp;</div>
                  <nav className={`navigation__nav ${isOpen ? 'open' : ''}`}>
                    <ul className="navigation__list">
                      <img src={Logo} alt="logo" height={100} />
                      <Account
                        address={address}
                        localProvider={localProvider}
                        userSigner={userSigner}
                        web3Modal={web3Modal}
                        loadWeb3Modal={loadWeb3Modal}
                        logoutOfWeb3Modal={logoutOfWeb3Modal}
                        blockExplorer={blockExplorer}
                      />

                      <li className="navigation__item" style={{ display: 'grid' }}>
                        <Link
                          to="/cakevault"
                          className="navigation__link"
                          onClick={handleLinkClick}
                          style={{ minWidth: '400px' }}
                        >
                          CAKE Boosted Vault
                        </Link>
                        <Link
                          to="/usdtvault"
                          className="navigation__link"
                          onClick={handleLinkClick}
                          style={{ minWidth: '400px' }}
                        >
                          USDT SA LP Vault
                        </Link>
                        <Link
                          to="https://app.gitbook.com/o/dZD0AzCezpv04z1sZHXD/s/ESuTLt4gXihCEvHpOWlZ/"
                          className="navigation__link"
                          onClick={handleLinkClick}
                          style={{ minWidth: '250px' }}
                        >
                          Doc's
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
