import React from 'react'

export default function FaqCAKE() {
  return (
    <div>
      <div>
        <h3
          className="fn-label gradient-headers"
          style={{ marginBottom: '20px', marginTop: '200px' }}
        >
          {' '}
          Details{' '}
        </h3>
        <div className=" fs_accordion-2_header">
          <div style={{ padding: '10px' }}>
            <div className="fs_accordion-2_label">Overview</div>
          </div>
        </div>
        <h1
          className="heading-15"
          style={{ marginBottom: '20px', marginTop: '20px' }}
        >
          <strong className="bold-text">
            Galaxy Boosted CAKE Vault is a private lending vault. Supplied CAKE
            can only be borrowed by other Vaults in the Galaxy Protocol for
            establishing liquidity positions and earning yield farming rewards.
            The lending rate depends on the amount of utilization. CAKE
            suppliers can deposit and withdraw at any time.
          </strong>
        </h1>
      </div>
      <div className=" fs_accordion-2_header">
        <div style={{ padding: '10px' }}>
          <div className="fs_accordion-2_label">Vault Strategy </div>
        </div>
      </div>
      <h1
        className="heading-15"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <strong className="bold-text">
          User supplied CAKE is "Flash Swapped" into 65% more CAKE using a
          liquidity pool. The full amount is supplied to our own Supply/Lending
          protocol (a Compound Finance v2/IronBank Fork). Then the flash swapped
          amount of CAKE is borrowed from our protocol to pay back the flash
          swap. Interest is paid on the full amount of supplied cake and a small
          amount of interest is accrued to borrow from the protocol. This
          process allows for leverage to be applied to the supplied funds at
          minimal cost and with manageable risks. A similar flash swap process
          is also utilized to withdraw funds from the vault.
        </strong>
      </h1>
      <div className=" fs_accordion-2_header">
        <div style={{ padding: '10px' }}>
          <div className="fs_accordion-2_label">Benefit</div>
        </div>
      </div>
      <h1
        className="heading-15"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <strong className="bold-text">
          Allows the usage of flash swaps as additional leverage at minimal
          cost. Deposit and Withdraw at any time. Fully on chain, verified &
          audited contracts using a non-custodial process. Contribute to a
          community of those looking to make DeFI more efficient
        </strong>
      </h1>
      <div className=" fs_accordion-2_header">
        <div style={{ padding: '10px' }}>
          <div className="fs_accordion-2_label">Risk</div>
        </div>
      </div>
      <h1
        className="heading-15"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <strong className="bold-text">
          The Supply/Lending protocol requires a healthy status or anyone can
          liquidate the position to bring the protocol back into a healthy
          status. Vault will only use 65% of the available 80% borrow amount.
          This should ensure a very limited risk of liquidations.
        </strong>
      </h1>
    </div>
  )
}
