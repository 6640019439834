import React from 'react'

export default function PageWrapper() {
  return (
    <div>
      <div className="page-wrapper Background" style={{ zIndex: '-10' }}>
        
      </div>
    </div>
  )
}
