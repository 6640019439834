import React, { useState, useEffect } from "react";
import { cakeToUSD, earnedCake, formatWei } from "../../utils";
import { BigNumber, ethers, utils } from "ethers";
import Cake from "../../images/CakeToken.png";
import lending from "../../images/lending.png";
import borrow from "../../images/borrow.png";
import supply from "../../images/supply.png";

import CardDepositCake from "./Card/CardDepositCake";
import CardWithdrawCake from "./Card/CardWithdrawCake";

const ETH_MANTISSA = 1e18;
const BLOCKS_PER_DAY = 28767;
const DAYS_PER_YEAR = 365;

export default function DashboardCAKE(props) {
  const { address, tx, writeContracts, readContracts } = props;
  const [tvl, setTvl] = useState();
  const [vaultShares, setVaultShares] = useState();
  const [shares, setShares] = useState(BigNumber.from("0"));
  const [cakeBalc, setCakeBalc] = useState(BigNumber.from("0"));
  const [assets, setAssets] = useState();
  const [amount, setAmount] = useState("0.00");
  const [approvedAmount, setApprovedAmount] = useState(BigNumber.from("0"));
  const [sharePrice, setSharePrice] = useState(0);
  const [supplyAPY, setSupplyAPY] = useState();
  const [borrowAPY, setBorrowAPY] = useState();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  useEffect(() => {
    if (!writeContracts.GalaxyYieldVault) {
      console.log("warning -123:");
      return;
    }
    console.log("========================================================");
    console.log("writeContracts - 123: ", writeContracts);
    console.log("========================================================");
    Promise.all([
      readContracts.GCAKE?.supplyRatePerBlock(),
      readContracts.GCAKE?.borrowRatePerBlock(),
      readContracts.GalaxyYieldVault?.totalSupply(),
      readContracts.GalaxyYieldVault?.getPricePerFullShare(),
      readContracts.GalaxyYieldVault?.balanceOf(address),
      readContracts.CAKE?.balanceOf(address),
      readContracts.CAKE?.allowance(
        address,
        writeContracts.GalaxyYieldVault.address
      ),
    ])
      .then(
        ([
          supplyRate,
          borrowRate,
          totalSupply,
          pricePerShare,
          userShares,
          cakeBalance,
          approvedAmount,
        ]) => {
          const supplyApy =
            (Math.pow(
              (supplyRate / ETH_MANTISSA) * BLOCKS_PER_DAY + 1,
              DAYS_PER_YEAR
            ) -
              1) *
            100;
          console.log(`💶 - Supply APY ${supplyApy.toFixed(2)} %`);
          setSupplyAPY(supplyApy);
          const borrowApy =
            (Math.pow(
              (borrowRate / ETH_MANTISSA) * BLOCKS_PER_DAY + 1,
              DAYS_PER_YEAR
            ) -
              1) *
            100;
          console.log(`💶 - Borrow APY ${borrowApy.toFixed(2)} %`);
          setBorrowAPY(borrowApy);

          setVaultShares(totalSupply.toString());
          console.log("supply", totalSupply);

          console.log("_pricePerShare1", pricePerShare / 1e18);
          console.log(
            "tvl",
            pricePerShare.mul(totalSupply).div("1000000000000000000").toString()
          );
          setTvl(
            pricePerShare.mul(totalSupply).div("1000000000000000000").toString()
          );

          console.log("user shares", userShares.toString());
          setShares(userShares);

          cakeToUSD()
            .then((val) => {
              console.log("cake usd val", val);
              setSharePrice((formatWei(userShares) * val).toFixed(4));
            })
            .catch((err) => {
              setSharePrice(0);
            });

          setAssets(
            pricePerShare.mul(userShares).div("1000000000000000000").toString()
          );

          setCakeBalc(cakeBalance);

          setApprovedAmount(approvedAmount);
        }
      )
      .catch((err) => {
        console.log("error retrieving data", err);
      });
  }, [address, tx, readContracts, writeContracts]);

  console.log("approved Amount", approvedAmount);
  console.log("connected account", address);
  console.log("tvl", tvl);

  const handleDepositClick = () => {
    setShowDepositModal(true);
  };

  const handleWithdrawClick = () => {
    setShowWithdrawModal(true);
  };
  const handleCloseModal = () => {
    setShowDepositModal(false);
    setShowWithdrawModal(false);
  };
  return (
    <div>
      <div className="TopHero">
        <div className="container2 w-container">
          <div className="div-block-13">
            <h5 className="gray">Pancakeswap (BNB)</h5>
            <h1 className="toplabel">CAKE Flash Boosted Vault</h1>
            <img
              src={Cake}
              alt="Web3 DeFi Dapp Interface"
              className="img-fluid"
              height={100}
              width={100}
            />
          </div>
          <div
            className="w-layout-grid grid itemsCenter gap15 white-grad"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <div className="div-block-27-cake img-cake ">
              <img
                src={lending}
                loading="lazy"
                alt=""
                className="img-cake"
                width={40}
                height={40}
              />
              <div className="pt-10">
                <h4 className="gray">Supply APY</h4>
                <h3 className="secondary">{supplyAPY?.toFixed(2)} %</h3>
              </div>
            </div>
            <div className="div-block-27-cake img-cake">
              <img
                src={borrow}
                loading="lazy"
                alt=""
                className="img-cake"
                width={40}
                height={40}
              />
              <div className="pt-10">
                <h4 className="gray">Borrow APY</h4>
                <h3 className="secondary">{borrowAPY?.toFixed(2)} %</h3>
              </div>
            </div>
            <div className="div-block-27-cake img-cake">
              <img
                src={supply}
                loading="lazy"
                alt=""
                className="img-cake"
                width={40}
                height={40}
              />
              <div className="pt-10">
                <h4 className="gray">Total Supply</h4>
                <h3 className="secondary">{formatWei(vaultShares)} Cake</h3>
              </div>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
            ></form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-2 w-container desktop">
        <h3 className="fn-label gradient-headers">My Shares</h3>
        <div className="div-block-17 d-cont">
          <div className="div-block-14">
            <div>
              <div className="div-block-16">
                <h4 id="animated-gradient" className="heading-14 gray">
                  Share Value:
                </h4>
                <div className="div-block-18">
                  <h3 className="heading-12 secondary">{formatWei(assets)}</h3>
                  <h3 className="heading-12 secondary">
                    CAKE ( {sharePrice} USD )
                  </h3>
                </div>
              </div>
              <div className="div-block-16">
                <div className="div-block-16">
                  <h4 className="heading-14 d-contents gray">Shares:</h4>
                  <div className="div-block-18">
                    <h3 className="heading-12 secondary">
                      {formatWei(shares)}{" "}
                    </h3>
                    <h3 className="heading-14 lower secondary">glxCAKE</h3>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="div-block-14 d-block">
                <h4 className="heading-14 d-contents gray">User Balance:</h4>
                <div className="div-block-18">
                  <h3 className="heading-12 secondary">
                    {" "}
                    {formatWei(cakeBalc)}
                  </h3>
                  <h3 className="heading-14 lower secondary">CAKE</h3>
                </div>
              </div>
              <div className="div-block-16">
                <h4 className="heading-14 d-contents gray">Earned Amount:</h4>
                <div className="div-block-18">
                  <h3 className="heading-12 secondary">
                    {" "}
                    {formatWei(
                      earnedCake(
                        assets?.toString(),
                        shares?.toString()
                      ).toString()
                    )}
                  </h3>
                  <h3 className="heading-14 lower secondary">CAKE</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-14 button-wrapper">
            <div className="div-block-16" />
            <div fs-component-name="hacks_1" className="example_block">
              <div className="hacks-1_component w-form hacks-1">
                <button
                  className="hacks-1_submit w-button dashboard-btn dash-btn"
                  onClick={handleDepositClick}
                >
                  Deposit
                </button>
                <button
                  className="hacks-1_submit w-button dashboard-btn dash-btn"
                  onClick={handleWithdrawClick}
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
          {showDepositModal && (
            <CardDepositCake
              readContracts={readContracts}
              address={address}
              writeContracts={writeContracts}
              tx={tx}
              onClose={() => setShowDepositModal(false)}
            />
          )}
          {showWithdrawModal && (
            <CardWithdrawCake
              readContracts={readContracts}
              address={address}
              writeContracts={writeContracts}
              tx={tx}
              onClose={() => setShowWithdrawModal(false)}
            />
          )}
        </div>
      </div>

      <div
        id="mobile"
        className="container-2 w-container mobile"
        style={{ display: "none" }}
      >
        <h3 className="fn-label gradient-headers">My Shares</h3>
        <div className="div-block-17 d-cont">
          <h4 id="animated-gradient" className="heading-14 gray">
            Share Value:
          </h4>
          <h3 className="heading-12 secondary">
            {formatWei(assets)} CAKE &nbsp;( {sharePrice} USD )
          </h3>
          <div className="div-block-16">
            <h4 className="heading-14 d-contents gray">Shares:</h4>
            <div className="div-block-18">
              <h3 className="heading-12 secondary">
                {formatWei(shares)} &nbsp; glxCAKE
              </h3>
            </div>

            <div className="div-block-16">
              <h4 className="heading-14 d-contents gray">User Balance:</h4>
              <div className="div-block-18">
                <h3 className="heading-12 secondary">
                  {" "}
                  {formatWei(cakeBalc)}&nbsp; CAKE
                </h3>
              </div>
              <div className="div-block-16">
                <h4 className="heading-14 d-contents gray">Earned Amount:</h4>
                <div className="div-block-18">
                  <h3 className="heading-12 secondary">
                    {" "}
                    {formatWei(
                      earnedCake(
                        assets?.toString(),
                        shares?.toString()
                      ).toString()
                    )}
                    &nbsp; CAKE
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="div-block-14 button-wrapper">
            <div className="div-block-16" />
            <div fs-component-name="hacks_1" className="example_block">
              <div className="hacks-1_component w-form"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}