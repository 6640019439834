import React from 'react'
import CakeImg from '../images/CakeImg.jpeg'
import USDTImg from '../images/USDTImg.png'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
      <div className="container-main">
      <div className="card1">
          <div className="cardwrap">
            <div className="card-image">
              <img src={CakeImg} loading="lazy" alt="" className="image-2" />
            </div>
            <Link to="/cakevault">
              <div className="card-bottom-wrap">
                <div className="card-top-text">
                  <div className="card-top-text">
                    <h5 className="heading4">Pancakeswap ( BNB )</h5>
                    <h4 className="heading-card">
                    CAKE&nbsp;Flash Yield Vault
                    </h4>
                  </div>
                </div>
                <div className="card-middle-text">
                  <h6 className="heading2">
                    <strong className="bold-text-card">
                    Galaxy Boosted CAKE Vault is a private lending vault.
                    Supplied CAKE can only be borrowed by other Vaults in the
                    Galaxy Protocol for establishing liquidity positions and
                    earning yield farming rewards.
                    </strong>
                  </h6>
                </div>
              {/*  <div className="card-bottom-text">
                  <h4 className="heading4">APY: </h4>
                  <h4 className="heading4"> 5% </h4>
                </div> */}
              </div>
            </Link>
          </div>
        </div>

        <div className="card1">
          <div className="cardwrap">
            <div className="card-image">
              <img src={USDTImg} loading="lazy" alt="" className="image-2" />
            </div>
            <Link to="/usdtvault">
              <div className="card-bottom-wrap">
                <div className="card-top-text">
                  <div className="card-top-text">
                    <h5 className="heading4">Pancakeswap ( BNB )</h5>
                    <h4 className="heading-card">
                    USDT Single Asset Yield Vault
                    </h4>
                  </div>
                </div>
                <div className="card-middle-text">
                  <h6 className="heading2">
                    <strong className="bold-text-card">
                    Galaxy USDT Single Asset Liquidity Yield Vault that
                      combines user supplied USDT and borrows CAKE from our
                      private supply.
                    </strong>
                  </h6>
                </div>
            {/*    <div className="card-bottom-text">
                  <h4 className="heading4">APY: </h4>
                  <h4 className="heading4">18% </h4>
                </div> */}
              </div>
            </Link>
          </div>
        </div>

        
       
      </div>
    </div>
  )
}
