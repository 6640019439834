import React from 'react'
import DashboardUSDT from '../components/InfinityYield/DashboardUSDT'
import FaqUSDT from '../components/InfinityYield/FaqUSDT'
import PageWrapper from '../components/InfinityYield/PageWrapper'
import Sidebar from '../components/InfinityYield/Sidebar'

export default function USDTSALPVault(props) {
  return (
    <div>
      <div className="PageWrapper">
      <Sidebar />
        <PageWrapper />
        <DashboardUSDT {...props} />
        <FaqUSDT />
        <div style={{ paddingTop: '100px' }}>
          <div className="fn-footer-line bg-green"></div>
          <h6
            style={{
              color: '#a4bcbc',
              margin: '0 auto',
              textAlign: 'center',
              fontSize: '10px',
              padding: '20px',
            }}
          >
            © 2023 - All rights reserved. Galaxy Finance
          </h6>
        </div>
      </div>
    </div>
  )
}
