import React from 'react'

export default function FaqUSDT() {
  return (
    <div>
      <div>
        <h3
          className="fn-label gradient-headers"
          style={{ marginBottom: '20px', marginTop: '200px' }}
        >
          {' '}
          Details{' '}
        </h3>
        <div className=" fs_accordion-2_header bg-green">
          <div style={{ padding: '10px' }}>
            <div className="fs_accordion-2_label">Overview</div>
          </div>
        </div>
        <h1
          className="heading-15"
          style={{ marginBottom: '20px', marginTop: '20px' }}
        >
          <strong className="bold-text">
            Galaxy USDT Single Asset Liquidity Yield Vault that combines user
            supplied USDT and borrows CAKE from our private supply.
          </strong>
        </h1>
      </div>
      <div className=" fs_accordion-2_header bg-green">
        <div style={{ padding: '10px' }}>
          <div className="fs_accordion-2_label">Vault Strategy </div>
        </div>
      </div>
      <h1
        className="heading-15"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <strong className="bold-text">
          Using supplied USDT and CAKE borrowed from our own supply, liquidity
          is added to Pancake v2 CAKE-USDT. The resulting LP Tokens are
          deposited into an APY boosted Pancake Farm. CAKE rewards are regularly
          harvested and compounded back into more liquidity and farming.
        </strong>
      </h1>
      <div className=" fs_accordion-2_header bg-green">
        <div style={{ padding: '10px' }}>
          <div className="fs_accordion-2_label">Benefit</div>
        </div>
      </div>
      <h1
        className="heading-15"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <strong className="bold-text">
          By only supplying USDT and borrowing CAKE from our private supply,
          there is no exposure to "impermanent losses" that providing liquidity
          typically includes. Additionally, in aggregate we can get a higher
          boosted APY than a single depositor typically could achieve in the
          Pancake Farm.
        </strong>
      </h1>
      <div className=" fs_accordion-2_header bg-green">
        <div style={{ padding: '10px' }}>
          <div className="fs_accordion-2_label">Risk</div>
        </div>
      </div>
      <h1
        className="heading-15"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <strong className="bold-text">
          There is a very limited risk of the borrowed CAKE being liquidated in
          very extreme market conditions. We mitigate this risk by paying 0.25%
          of the received CAKE rewards back to the borrowed amount on every
          harvest.
        </strong>
      </h1>
    </div>
  )
}
