import React from 'react'

export default function Footer() {
  return (
    <div style={{ paddingTop: '100px' }}>
      <div className="fn-footer-line"></div>
      <h6
        style={{
          color: '#a4bcbc',
          margin: '0 auto',
          textAlign: 'center',
          fontSize: '10px',
          padding: '20px',
        }}
      >
        © 2023 - All rights reserved. Galaxy Finance
      </h6>
    </div>
  )
}
