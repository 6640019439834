import { formatUnits } from 'ethers/lib/utils'
import { BLOCKS_PER_YEAR, TOKEN_PER_BLOCK } from '../constants'
import BigNumber from 'bignumber.js'
export const formatWei = (weiAmount, fixed = 4) => {
  let formatted = '0'
  try {
    formatted = parseFloat(formatUnits(weiAmount)).toFixed(fixed)
  } catch (e) {}
  return formatted
}

export const earnedCake = (assets, shares) => {
  console.log('earnedCake:shares', shares)
  console.log('earnedCake:assets', assets)
  let earned = new BigNumber('0')
  try {
    let _assets = new BigNumber(assets)
    let _shares = new BigNumber(shares)
    if (_assets.gte(_shares)) {
      console.log('is gt', _assets.minus(_shares).toString())
      return _assets.minus(_shares)
    }
  } catch (e) {
    console.log(e)
  }
  return earned
}

export const cakeToUSD = () => {
  const url =
    'https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?contract_addresses=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82&vs_currencies=usd&precision=6'
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        if (!data['0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82']) {
          throw new Error('Token data is missing from response')
        }
        resolve(data['0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82']['usd'])
      })
      .catch((error) => {
        console.error('Error fetching token price:', error)
        reject(error)
      })
  })
}

// cakeToUSD()
//  .then((price) => console.log('Token price in USD:', price))
//  .catch((error) => console.error('Failed to get token price:', error))

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */

export const getPoolApr = (
  stakingTokenPrice,
  rewardTokenPrice,
  totalStaked,
  tokenPerBlock,
) => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
    .times(tokenPerBlock)
    .times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(
    totalStaked,
  )
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

// console.log('apr', getPoolApr('1', '1.64', '28740209575406216348', TOKEN_PER_BLOCK));
