import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch, useLocation, Link } from 'react-router-dom'
import 'antd/dist/antd.css'
import {
  useBalance,
  useContractLoader,
  useContractReader,
  useUserProviderAndSigner,
} from 'eth-hooks'
import { useExchangeEthPrice } from 'eth-hooks/dapps/dex'
import { Contract, ThemeSwitch } from '../../components'
import { NETWORKS, ALCHEMY_KEY } from '../../constants'
import externalContracts from '../../contracts/external_contracts'
// contracts
import deployedContracts from '../../contracts/hardhat_contracts.json'
import { getRPCPollTime, Transactor, Web3ModalSetup } from '../../helpers'
import { Home, cakevault, usdtvault } from '../../views'
import { useStaticJsonRPC, useGasPrice } from '../../hooks'
import { Account, NetworkDisplay } from '../../components'
import InfinityYieldCAKE from '../../views/CAKEBoostVault'
import InfinityYieldUSDT from '../../views/USDTSALPVault'

import Logo from '../../images/logo.png'
import Twitter from '../../images/twitter.psd.png'
import Medium from '../../images/Medium.png'
import Slack from '../../images/slack.psd.png'
import Telegram from '../../images/Telegram.png'

const { ethers } = require('ethers')
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/scaffold-eth/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Alchemy.com & Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.bsc // <------- select your target frontend network (localhost, goerli, xray, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = true
const NETWORKCHECK = true
const USE_BURNER_WALLET = true // toggle burner wallet feature
const USE_NETWORK_SELECTOR = false

const web3Modal = Web3ModalSetup()

// 🛰 providers
const providers = [
  'https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406',
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  'https://rpc.scaffoldeth.io:48544',
]

export default function Sidebar(props) {
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = [initialNetwork.name, 'mainnet', 'goerli']

  const [injectedProvider, setInjectedProvider] = useState()
  const [address, setAddress] = useState()
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0])
  const location = useLocation()

  const targetNetwork = NETWORKS[selectedNetwork]

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER
      ? process.env.REACT_APP_PROVIDER
      : targetNetwork.rpcUrl,
  ])

  console.log('localProvider', localProvider)

  const mainnetProvider = useStaticJsonRPC(providers, localProvider)

  // Sensible pollTimes depending on the provider you are using
  const localProviderPollingTime = getRPCPollTime(localProvider)
  const mainnetProviderPollingTime = getRPCPollTime(mainnetProvider)

  if (DEBUG) console.log(`Using ${selectedNetwork} network`)

  // 🛰 providers
  if (DEBUG) console.log('📡 Connecting to Mainnet Ethereum')

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider()
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == 'function'
    ) {
      await injectedProvider.provider.disconnect()
    }
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(
    targetNetwork,
    mainnetProvider,
    mainnetProviderPollingTime,
  )

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(
    targetNetwork,
    'FastGasPrice',
    localProviderPollingTime,
  )
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(
    injectedProvider,
    localProvider,
    USE_BURNER_WALLET,
  )
  const userSigner = userProviderAndSigner.signer

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress()
        setAddress(newAddress)
      }
    }
    getAddress()
  }, [userSigner])

  // You can warn the user if you would like them to be on a specific network
  const localChainId =
    localProvider && localProvider._network && localProvider._network.chainId
  const selectedChainId =
    userSigner &&
    userSigner.provider &&
    userSigner.provider._network &&
    userSigner.provider._network.chainId

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice)

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(
    localProvider,
    address,
    localProviderPollingTime,
  )

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(
    mainnetProvider,
    address,
    mainnetProviderPollingTime,
  )

  // const contractConfig = useContractConfig();

  const contractConfig = {
    deployedContracts: deployedContracts || {},
    externalContracts: externalContracts || {},
  }

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig)

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(
    userSigner,
    contractConfig,
    localChainId,
  )

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig)

  // If you want to call a function on a new block
  // useOnBlock(mainnetProvider, () => {
  //   console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  // });

  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader(
    mainnetContracts,
    'DAI',
    'balanceOf',
    ['0x34aA3F359A9D614239015126635CE7732c18fDF3'],
    mainnetProviderPollingTime,
  )

  // keep track of a variable from the contract in the local React state:
  const purpose = useContractReader(
    readContracts,
    'YourContract',
    'purpose',
    [],
    localProviderPollingTime,
  )

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:", addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log(
        '_____________________________________ 🏗 scaffold-eth _____________________________________',
      )
      console.log('🌎 mainnetProvider', mainnetProvider)
      console.log('🏠 localChainId', localChainId)
      console.log('👩‍💼 selected address:', address)
      console.log('🕵🏻‍♂️ selectedChainId:', selectedChainId)
      console.log(
        '💵 yourLocalBalance',
        yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : '...',
      )
      console.log(
        '💵 yourMainnetBalance',
        yourMainnetBalance
          ? ethers.utils.formatEther(yourMainnetBalance)
          : '...',
      )
      console.log('📝 readContracts', readContracts)
      console.log('🌍 DAI contract on mainnet:', mainnetContracts)
      console.log('💵 yourMainnetDAIBalance', myMainnetDAIBalance)
      console.log('🔐 writeContracts', writeContracts)
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
    myMainnetDAIBalance,
  ])

  const loadWeb3Modal = useCallback(async () => {
    //const provider = await web3Modal.connect();
    const provider = await web3Modal.requestProvider()
    setInjectedProvider(new ethers.providers.Web3Provider(provider))

    provider.on('chainChanged', (chainId) => {
      console.log(`chain changed to ${chainId}! updating providers`)
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    provider.on('accountsChanged', () => {
      console.log(`account changed!`)
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    // Subscribe to session disconnection
    provider.on('disconnect', (code, reason) => {
      console.log(code, reason)
      logoutOfWeb3Modal()
    })
    // eslint-disable-next-line
  }, [setInjectedProvider])

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal()
    }
    //automatically connect if it is a safe app
    const checkSafeApp = async () => {
      if (await web3Modal.isSafeApp()) {
        loadWeb3Modal()
      }
    }
    checkSafeApp()
  }, [loadWeb3Modal])

  const [isOpen, setIsOpen] = useState(false)

  function toggleMenu() {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <div className="sidebar wf-section">
        <div>
          <div className="div-block">
            <Link to="/cakevault">
              <h5 className="heading-2 gray sidebarText">CAKE Boosted Vault</h5>
            </Link>
            <Link to="/usdtvault">
              {' '}
              <h6 className="heading-2 gray sidebarText">USDT SA LP Vault</h6>
            </Link>
            <Account
              address={address}
              localProvider={localProvider}
              userSigner={userSigner}
              web3Modal={web3Modal}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              blockExplorer={blockExplorer}
            />
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            ></div>
          </div>
          <div>
            <h4
              className="side-socials"
              style={{ justifyContent: 'space-between' }}
            >
              <a
                href="https://t.me/GalaxyFinanceDeFi"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Telegram}
                  loading="lazy"
                  alt="Illustration of secure blockchain technology powering our DeFi DApp"
                  height={30}
                  className="image-2"
                />
              </a>
              <a
                href="https://twitter.com/Galaxy__finance"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Twitter}
                  loading="lazy"
                  alt="Graph showing the growth of assets managed by our DeFi DApp"
                  height={30}
                  className="image-2"
                />
              </a>
              <a href="https://www.slack.com/" target="_blank" rel="noreferrer">
                <img
                  src={Slack}
                  loading="lazy"
          alt="Team behind the development of our innovative DeFi DApp"
                  height={30}
                  className="image-2"
                />
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}
