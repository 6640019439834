import { BigNumber, ethers, utils } from 'ethers'
import React, { useEffect, useState } from 'react'
import './Card-deposit-USDT.css'
import USDT from '../../../images/usdt.png'
export default function CardWithdrawUSDT({
  readContracts,
  address,
  writeContracts,
  tx,
  onClose,
}) {
  const [amount, setAmount] = useState(0)
  const [approvedAmount, setApprovedAmount] = useState(BigNumber.from('0'))
  const [usdtBal, setUSDTBal] = useState(BigNumber.from('0'))
  const [availableBalance, setAvailableBalance] = useState('0')

  useEffect(() => {
    const fetchUSDTBalance = async () => {
      try {
        const usdtBalance = await readContracts.GalaxyUSDTVault?.balanceOf(
          address,
        )
        setUSDTBal(usdtBalance)
        const formattedBalance = utils.formatUnits(usdtBalance, 18)
        setAvailableBalance(parseFloat(formattedBalance).toFixed(2))
      } catch (error) {
        console.log('Error retrieving GLX-USDT-CAKE balance:', error)
      }
    }

    fetchUSDTBalance()
  }, [address, readContracts.GalaxyUSDTVault])

  useEffect(() => {
    const fetchApprovedBalance = async () => {
      try {
        if (writeContracts.GalaxyUSDTVault && writeContracts.GalaxyUSDTVault) {
          const approvedGalaxyUSDTVault = await readContracts.GalaxyUSDTVault?.allowance(
            address,
            writeContracts.GalaxyUSDTVault.address,
          )
          setApprovedAmount(approvedGalaxyUSDTVault)
        }
      } catch (error) {
        console.log('Error retrieving GLX-USDT-CAKE allowance:', error)
      }
    }
    fetchApprovedBalance()
  }, [address, readContracts.GalaxyUSDTVault, writeContracts.GalaxyUSDTVault])

  const handlePercentageClick = (percentage) => {
    setAmount((availableBalance * percentage) / 100)
  }

  const handleApproveClick = async () => {
    try {
      if (
        amount > 0 &&
        writeContracts.GalaxyUSDTVault &&
        writeContracts.GalaxyUSDTVault
      ) {
        const approvalAmount = ethers.utils.parseEther(amount.toString())
        const approvalTx = await tx(
          writeContracts.GalaxyUSDTVault.approve(
            writeContracts.GalaxyUSDTVault.address,
            approvalAmount,
          ),
          (update) => {
            if (
              update &&
              (update.status === 'confirmed' || update.status === 1)
            ) {
              console.log('🍾 Transaction ' + update.hash + ' finished!')
              setApprovedAmount(approvalAmount)
            }
          },
        )
        console.log('Awaiting wallet confirmation...', approvalTx)
        console.log(await approvalTx)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleWithdrawClick = async () => {
    try {
      if (amount > 0 && writeContracts.GalaxyUSDTVault.withdraw && isApproved) {
        const withdrawAmount = ethers.utils.parseEther(amount.toString())
        const withdrawTx = await tx(
          writeContracts.GalaxyUSDTVault.withdraw(
            withdrawAmount,
            address,
            address,
          ),
          (update) => {
            if (
              update &&
              (update.status === 'confirmed' || update.status === 1)
            ) {
              console.log('🍾 Transaction ' + update.hash + ' finished!')
            }
          },
        )
        console.log('Awaiting wallet confirmation...', withdrawTx)
        console.log(await withdrawTx)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    const updateUSDTBal = async () => {
      try {
        const _usdtBal = await readContracts.GalaxyUSDTVault.balanceOf(address)
        setUSDTBal(_usdtBal)
      } catch (e) {
        console.log('error retrieving GLX-USDT-CAKE bal!', e)
      }
    }

    updateUSDTBal()
  }, [address, readContracts])

  const isApproved =
    approvedAmount &&
    amount &&
    approvedAmount.gte(ethers.utils.parseEther(amount.toString()))

  return (
    <div className="card-container">
      <div className="card">
        <div className="wrapper-closebtn d-flex justify-content-between">
          <h4 style={{ color: 'white' }}>Withdraw glxUSDTCAKE</h4>
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>

        <div className="">
          <div className="card__input-section modal-container">
            <div className="justify-content-between d-flex">
              <div
                className="d-flex bg-black border-r-15"
                style={{
                  paddingLeft: '10px',
                  paddingBottom: '5px',
                  paddingRight: '10px',
                }}
              >
                {/*   <img
                  src={USDT}
                  alt=""
                  height={20}
                  width={20}
                  className="mt-5"
                />{' '} */}
                <h5
                  style={{
                    color: 'white',
                  }}
                >
                  glxUSDTCAKE LP
                </h5>
              </div>

              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Math.round(e.target.value))}
                style={{ color: 'white' }}
              />
            </div>
            <h6 style={{ color: 'white' }}>
              glxUSDTCAKE Balance: {parseFloat(availableBalance)}
            </h6>
          </div>
          <div className="percentage-container">
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(25)}
            >
              25%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(50)}
            >
              50%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(75)}
            >
              75%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(100)}
            >
              Max
            </button>
          </div>
        </div>
        {isApproved ? (
          <button className="deposit-btn" onClick={handleWithdrawClick}>
            Withdraw
          </button>
        ) : (
          <button className="approve-btn" onClick={handleApproveClick}>
            Approve
          </button>
        )}
      </div>
    </div>
  )
}
