import { BigNumber, ethers, utils } from 'ethers'
import React, { useEffect, useState } from 'react'
import './Card-deposit-USDT.css'
import USDT from '../../../images/usdt.png'
export default function CardDepositUSDT({
  readContracts,
  address,
  writeContracts,
  tx,
  onClose,
}) {
  const [amount, setAmount] = useState(0)
  const [approvedAmount, setApprovedAmount] = useState(BigNumber.from('0'))
  const [usdtBal, setUSDTBal] = useState(BigNumber.from('0'))
  const [availableBalance, setAvailableBalance] = useState('0')

  useEffect(() => {
    const fetchUSDTBalance = async () => {
      try {
        const usdtBalance = await readContracts.GUSDT?.balanceOf(address)
        setUSDTBal(usdtBalance)
        const formattedBalance = utils.formatUnits(usdtBalance, 18)
        setAvailableBalance(parseFloat(formattedBalance).toFixed(2))
      } catch (error) {
        console.log('Error retrieving USDT balance:', error)
      }
    }

    fetchUSDTBalance()
  }, [address, readContracts.GUSDT])

  useEffect(() => {
    const fetchApprovedBalance = async () => {
      try {
        if (writeContracts.GUSDT && writeContracts.GalaxyUSDTVault) {
          const approvedUSDT = await readContracts.GUSDT?.allowance(
            address,
            writeContracts.GalaxyUSDTVault.address,
          )
          setApprovedAmount(approvedUSDT)
        }
      } catch (error) {
        console.log('Error retrieving USDT allowance:', error)
      }
    }
    fetchApprovedBalance()
  }, [address, readContracts.GUSDT, writeContracts.GalaxyUSDTVault])

  const handlePercentageClick = (percentage) => {
    setAmount(Math.floor((availableBalance * percentage) / 100))
  }

  const handleApproveClick = async () => {
    try {
      if (
        amount > 0 &&
        writeContracts.GUSDT &&
        writeContracts.GalaxyUSDTVault
      ) {
        const approvalAmount = ethers.utils.parseEther(amount.toString())
        const approvalTx = await tx(
          writeContracts.GUSDT.approve(
            writeContracts.GalaxyUSDTVault.address,
            approvalAmount,
          ),
          (update) => {
            if (
              update &&
              (update.status === 'confirmed' || update.status === 1)
            ) {
              console.log('🍾 Transaction ' + update.hash + ' finished!')
              setApprovedAmount(approvalAmount)
            }
          },
        )
        console.log('Awaiting wallet confirmation...', approvalTx)
        console.log(await approvalTx)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const handleDepositClick = async () => {
    try {
      if (
        amount > 0 &&
        writeContracts.GalaxyUSDTVault &&
        approvedAmount.gte(ethers.utils.parseEther(amount.toString()))
      ) {
        const depositAmount = ethers.utils.parseEther(amount.toString())
        const depositTx = await tx(
          writeContracts.GalaxyUSDTVault.deposit(depositAmount, address),
          (update) => {
            if (
              update &&
              (update.status === 'confirmed' || update.status === 1)
            ) {
              console.log('🍾 Transaction ' + update.hash + ' finished!')
            }
          },
        )
        console.log('Awaiting wallet confirmation...', depositTx)
        console.log(await depositTx)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    readContracts.GUSDT?.balanceOf(address)
      .then((_usdtBal) => {
        setUSDTBal(_usdtBal)
      })
      .catch((e) => console.log('error retrieving usdt bal!'))
  }, [address, readContracts])

  const isApproved =
    approvedAmount &&
    amount &&
    approvedAmount.gte(ethers.utils.parseEther(amount.toString()))

  return (
    <div className="card-container">
      <div className="card">
        <div className="wrapper-closebtn d-flex justify-content-between">
          <h4 style={{ color: 'white' }}>Deposit USDT</h4>
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>

        <div className="">
          <div className="card__input-section modal-container">
            <div className="justify-content-between d-flex">
              <div
                className="d-flex bg-black border-r-15"
                style={{
                  paddingLeft: '10px',
                  paddingBottom: '5px',
                  paddingRight: '10px',
                }}
              >
                <img
                  src={USDT}
                  alt=""
                  height={20}
                  width={20}
                  className="mt-5 mr-5"
                />{' '}
                <h5
                  style={{
                    color: 'white',
                  }}
                >
                  USDT
                </h5>
              </div>

              <input
                type="number"
                step="0.1"
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
                style={{ color: 'white' }}
              />
            </div>
            <h6 style={{ color: 'white' }}>
              USDT Balance: {parseFloat(availableBalance)}
            </h6>
          </div>
          <div className="percentage-container">
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(25)}
            >
              25%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(50)}
            >
              50%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(75)}
            >
              75%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(100)}
            >
              Max
            </button>
          </div>
        </div>
        {isApproved ? (
          <button className="deposit-btn" onClick={handleDepositClick}>
            Deposit
          </button>
        ) : (
          <button className="approve-btn" onClick={handleApproveClick}>
            Approve
          </button>
        )}
      </div>
    </div>
  )
}
