import { BigNumber, ethers, utils } from 'ethers'
import React, { useEffect, useState } from 'react'
import './Card-deposit-Cake.css'
import CAKE from '../../../images/CakeToken.png'
export default function CardWithdrawCake({
  readContracts,
  address,
  writeContracts,
  tx,
  onClose,
}) {
  const [amount, setAmount] = useState(0)
  const [approvedAmount, setApprovedAmount] = useState(BigNumber.from('0'))
  const [cakeBal, setCAKEBal] = useState(BigNumber.from('0'))
  const [availableBalance, setAvailableBalance] = useState('0')

  useEffect(() => {
    const fetchCAKEBalance = async () => {
      try {
        const cakeBalance = await readContracts.GalaxyYieldVault?.balanceOf(
          address,
        )
        setCAKEBal(cakeBalance)
        const formattedBalance = utils.formatUnits(cakeBalance, 18)
        setAvailableBalance(parseFloat(formattedBalance).toFixed(2))
      } catch (error) {
        console.log('Error retrieving GLX-CAKE-CAKE balance:', error)
      }
    }

    fetchCAKEBalance()
  }, [address, readContracts.GalaxyYieldVault])

  useEffect(() => {
    const fetchApprovedBalance = async () => {
      try {
        if (
          writeContracts.GalaxyYieldVault &&
          writeContracts.GalaxyYieldVault
        ) {
          const approvedGalaxyYieldVault = await readContracts.GalaxyYieldVault?.allowance(
            address,
            writeContracts.GalaxyYieldVault.address,
          )
          setApprovedAmount(approvedGalaxyYieldVault)
        }
      } catch (error) {
        console.log('Error retrieving GLX-CAKE-CAKE allowance:', error)
      }
    }
    fetchApprovedBalance()
  }, [address, readContracts.GalaxyYieldVault, writeContracts.GalaxyYieldVault])

  const handlePercentageClick = (percentage) => {
    setAmount((availableBalance * percentage) / 100)
  }

  const handleApproveClick = async () => {
    try {
      if (
        amount > 0 &&
        writeContracts.GalaxyYieldVault &&
        writeContracts.GalaxyYieldVault
      ) {
        const approvalAmount = ethers.utils.parseEther(amount.toString())
        const approvalTx = await tx(
          writeContracts.GalaxyYieldVault.approve(
            writeContracts.GalaxyYieldVault.address,
            approvalAmount,
          ),
          (update) => {
            if (
              update &&
              (update.status === 'confirmed' || update.status === 1)
            ) {
              console.log('🍾 Transaction ' + update.hash + ' finished!')
              setApprovedAmount(approvalAmount)
            }
          },
        )
        console.log('Awaiting wallet confirmation...', approvalTx)
        console.log(await approvalTx)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleWithdrawClick = async (amount) => {
    try {
      if (
        amount > 0 &&
        writeContracts.GalaxyYieldVault.withdraw &&
        isApproved
      ) {
        const withdrawAmount = ethers.utils.parseEther(amount.toString())
        const withdrawTx = await tx(
          writeContracts.GalaxyYieldVault.withdraw(withdrawAmount),
          (update) => {
            if (
              update &&
              (update.status === 'confirmed' || update.status === 1)
            ) {
              console.log('🍾 Transaction ' + update.hash + ' finished!')
            }
          },
        )
        console.log('Awaiting wallet confirmation...', withdrawTx)
        console.log(await withdrawTx)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    const updateCAKEBal = async () => {
      try {
        const _cakeBal = await readContracts.GalaxyYieldVault.balanceOf(address)
        setCAKEBal(_cakeBal)
      } catch (e) {
        console.log('error retrieving GLX-CAKE-CAKE bal!', e)
      }
    }

    updateCAKEBal()
  }, [address, readContracts])

  const isApproved =
    approvedAmount &&
    amount &&
    approvedAmount.gte(ethers.utils.parseEther(amount.toString()))

  return (
    <div className="card-container">
      <div className="card">
        <div className="wrapper-closebtn d-flex justify-content-between">
          <h4 style={{ color: 'white' }}>Withdraw CAKE</h4>
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>

        <div className="">
          <div className="card__input-section modal-container">
            <div className="justify-content-between d-flex">
              <div
                className="d-flex bg-black border-r-15"
                style={{
                  paddingLeft: '10px',
                  paddingBottom: '5px',
                  paddingRight: '10px',
                }}
              >
                <img
                  src={CAKE}
                  alt=""
                  height={20}
                  width={20}
                  className="mt-5"
                  style={{ marginRight: '5px' }}
                />{' '}
                <h5
                  style={{
                    color: 'white',
                    paddingTop: '1px',
                    paddingRight: '5px',
                  }}
                >
                  glxCAKE
                </h5>
              </div>

              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Math.round(e.target.value))}
                style={{ color: 'white' }}
              />
            </div>
            <h6 style={{ color: 'white' }}>
              {' '}
              glxCAKE balance : {parseFloat(availableBalance)}
            </h6>
          </div>
          <div className="percentage-container">
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(25)}
            >
              25%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(50)}
            >
              50%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(75)}
            >
              75%
            </button>
            <button
              className="percentage-btn"
              onClick={() => handlePercentageClick(100)}
            >
              Max
            </button>
          </div>
        </div>
        {isApproved ? (
          <button className="deposit-btn" onClick={handleWithdrawClick}>
            Withdraw
          </button>
        ) : (
          <button className="approve-btn" onClick={handleApproveClick}>
            Approve
          </button>
        )}
      </div>
    </div>
  )
}
