import React from 'react'
import DashboardCAKE from '../components/InfinityYield/DashboardCAKE'
import FaqCAKE from '../components/InfinityYield/FaqCAKE'
import Footer from '../components/InfinityYield/Footer'
import PageWrapper from '../components/InfinityYield/PageWrapper'
import Sidebar from '../components/InfinityYield/Sidebar'

export default function CAKEBoostVault(props) {
  return (
    <div>
      <div className="PageWrapper">
      <Sidebar />
        <PageWrapper />
        <DashboardCAKE {...props} />
        <FaqCAKE />
        <Footer />
      </div>
    </div>
  )
}
