import React, { useState, useEffect } from 'react'
import { formatWei } from '../../utils'
import { BigNumber, ethers, utils } from 'ethers'
import Cake from '../../images/CakeToken.png'
import usdt from '../../images/usdt1.png'
import apy from '../../images/apy6.png'
import bank from '../../images/bank5.png'
import CardDepositUSDT from './Card/CardDepositUSDT'
import CardWithdrawUSDT from './Card/CardWithdrawUSDT'

const ethMantissa = 1e18
const blocksPerDay = 28767
const daysPerYear = 365

export default function DashboardUSDT(props) {
  const { address, tx, writeContracts, readContracts } = props
  const [tvl, setTvl] = useState()
  const [vaultShares, setVaultShares] = useState()
  const [shares, setShares] = useState(BigNumber.from('0'))
  const [usdtBal, setUSDTBal] = useState(BigNumber.from('0'))
  const [assets, setAssets] = useState()
  const [amount, setAmount] = useState('0.00')
  const [approvedAmount, setApprovedAmount] = useState(BigNumber.from('0'))
  const [sharePrice, setSharePrice] = useState(BigNumber.from('0'))
  const [supplyAPY, setSupplyAPY] = useState()
  const [borrowAPY, setBorrowAPY] = useState()
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)

  useEffect(() => {
    readContracts.GalaxyUSDTVault?.totalSupply()
      .then((_supply) => {
        setVaultShares(_supply.toString())
        console.log('supply', _supply)
        readContracts.GalaxyUSDTVault?.convertToAssets(_supply)
          .then((_pricePerShare) => {
            console.log('_pricePerShare1', _pricePerShare / 1e18)
            setSharePrice((_pricePerShare / 1e18).toFixed(7))
            console.log(
              'tvl',
              _pricePerShare
                .mul(_pricePerShare)
                .div('1000000000000000000')
                .toString(),
            )
            setTvl(
              Share.mul(_pricePerShare).div('1000000000000000000').toString(),
            )
          })
          .catch((e) => console.log('error retrieving _pricePerShare1 value!'))
      })
      .catch((e) => console.log('error retrieving tvl!'))

    readContracts.GalaxyUSDTVault?.balanceOf(address).then((_shares) => {
      setShares(_shares)
      readContracts.GalaxyUSDTVault?.convertToAssets(_shares)
        .then((_assets) => {
          readContracts.GalaxyUSDTVault?.getAssetsAmounts(_assets)
            .then((_assetsAmount) => {
              setAssets(_assetsAmount)
            })
            .catch((e) => console.log('error retrieving assets amount!'))
        })
        .catch((e) => console.log('error retrieving assets value!'))
    })

    readContracts.GUSDT?.balanceOf(address)
      .then((_usdtBalnc) => {
        setUSDTBal(_usdtBalnc)
      })
      .catch((e) => console.log('error retrieving usdt bal!'))

    readContracts.GUSDT?.allowance(
      address,
      writeContracts.GalaxyUSDTVault.address,
    )
      .then((_approvedAmount) => {
        setApprovedAmount(_approvedAmount)
      })
      .catch((e) => console.log('error retrieving allowance!'))
  }, [address, tx, readContracts, writeContracts])
  console.log('approved Amount', approvedAmount)
  console.log('connected account', address)
  console.log('tvl', tvl)

  const handleDepositClick = () => {
    setShowDepositModal(true)
  }

  const handleWithdrawClick = () => {
    setShowWithdrawModal(true)
  }
  const handleCloseModal = () => {
    setShowDepositModal(false);
    setShowWithdrawModal(false);
  }
  return (
    <div>
      <div className="TopHero">
        <div className="container w-container">
          <div className="div-block-13">
            <h5 className="gray">Pancakeswap (BNB)</h5>
            <h1 className="toplabel">
              USDT Single Asset Liquidity Yield Vault
            </h1>
            <img
              src={usdt}
              alt="cake"
              className="img-fluid"
              height={120}
              width={120}
            />
            <img
              src={Cake}
              alt="cake"
              className="img-fluid"
              height={100}
              width={100}
            />
            <div className="headingUSDT" style={{ textAlign: 'center' }}>
              <h4 className="heading-14-usdt gray">Supply USDT, Borrow CAKE</h4>
            </div>
          </div>
          <div className="w-layout-grid grid toprow smallbox">
            <div className="div-usdt">
              <img
                src={apy}
                alt="cake"
                className="img-fluid"
                height={40}
                width={40}
                style={{ marginBottom: '15px' }}
              />
              <div className="">
                <h4 className="gray">APY</h4>
                <h3 className="secondary">18%</h3>
              </div>
            </div>
            <div className="div-usdt">
              <img
                src={bank}
                alt="cake"
                className="img-fluid"
                height={40}
                width={40}
                style={{ marginBottom: '15px' }}
              />
              <div className="">
                <h4 className="gray">Total Supply</h4>
                <h3 className="secondary">
                  {formatWei(vaultShares)} glxUSDTCAKE
                </h3>
              </div>
            </div>
          </div>
          <div className="form-block-2 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
            ></form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-2 w-container container-green">
        <h3 className="fn-label gradient-headers">My Shares</h3>
        <div className="div-block-17 d-cont">
          <div className="div-block-14">
            <div className="div-block-16 boxwrap">
              <div className="">
                <div className="div-block-18">
                  <h3 className="heading-14 lower secondary">
                    {formatWei(assets?.assets1)}
                  </h3>
                  <h3
                    className="heading-14 lower secondary"
                    style={{ display: 'ruby' }}
                  >
                    USDT Supplied
                  </h3>
                </div>

                <div className="div-block-18">
                  <h3 className="heading-14 lower secondary">
                  {formatWei(assets?.assets0)}
                  </h3>
                  <h3
                    className="heading-14 lower secondary"
                    style={{ display: 'ruby' }}
                  >
                    CAKE Borrowed
                  </h3>
                </div>


              </div>

              <div className="w100 d-flex">
                <div className="div-block-16">
                  <h4 className="heading-14 gray">User USDT Balance:</h4>
                  <div className="div-block-18">
                    <h3 className="heading-14 lower secondary">
                      {formatWei(usdtBal)}
                    </h3>
                    <h3 className="heading-14 lower secondary"> USDT</h3>
                  </div>
                </div>
                <div className="div-block-16">
                  <h4 className="heading-14 gray">Shares:</h4>
                  <div className="div-block-18">
                    <h3 className="heading-14 lower secondary">
                      {formatWei(shares)}{' '}
                    </h3>
                    <h3 className="heading-14 lower secondary"> SHARES</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-14 button-wrapper">
            <div className="div-block-16" />
            <div fs-component-name="hacks_1" className="example_block">
              <div className="hacks-1_component w-form">
                <div className="div-block-26">
                  <button
                className="hacks-1_submit w-button dashboard-btn b-green"
                    onClick={handleDepositClick}
                  >
                    Deposit
                  </button>
                  <button
            className="hacks-1_submit w-button dashboard-btn b-green "
                    onClick={handleWithdrawClick}
                  >
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showDepositModal && (
              <CardDepositUSDT
                readContracts={readContracts}
                address={address}
                writeContracts={writeContracts}
                tx={tx}
                onClose={() => setShowDepositModal(false)}
              />
          )}
          {showWithdrawModal && (
              <CardWithdrawUSDT
                readContracts={readContracts}
                address={address}
                writeContracts={writeContracts}
                tx={tx}
                onClose={() => setShowWithdrawModal(false)}
              />
          )}
        </div>
      </div>
    </div>
  )
}
